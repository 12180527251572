<template>
<div class="container">
    <div class="alias" v-text="alias"></div>
    <div class="list">
        <div class="item">
            <div class="col asset">币种</div>
            <div class="col target-rate">目标占比</div>
            <div class="col curr-rate">实际占比</div>
            <div class="col funding-rate">当日费率</div>
        </div>
        <div class="item" v-for="item in list" :key="item.Asset">
            <div class="col asset" v-text="item.Asset"></div>
            <div class="col target-rate" v-text="item.TargetRate"></div>
            <div class="col curr-rate" v-text="item.CurrRate"></div>
            <div class="col funding-rate" v-text="item.LastDayFundingRate"></div>
        </div>
    </div>
</div>
</template>

<script>
import abs from "@/assets/js/abs";

export default {
    name: "SymbolDetail",
    data() {
        return {
            alias: '',
            list: [],
        };
    },
    mounted () {
        this.alias = this.$route.params.alias.toUpperCase()
        let url = this.api.urlFromAlias(this.alias)
        fetch(url + '/details', {
            method: 'POST',
            body: JSON.stringify({
                side: 'notice'
            })
        }).then(response => response.json()).then(data => {
            if (data.Code !== 0) {
                alert(data.Message)
                return
            }
            this.handle(data.Data.Symbols)
        }).catch(error => {
            alert(error)
        })
    },
    methods: {
        handle(details) {
            details.forEach(item => {
                if (!item.Asset) {
                    item.Asset = item.Symbol
                }
                item.CurrRate = abs.Util.formatNumber(item.CurrRate * 100, 2) + '%'
                item.TargetRate = abs.Util.formatNumber(item.TargetRate * 100, 2) + '%'
                item.LastDayFundingRate = abs.Util.formatNumber(item.LastDayFundingRate * 100, 3) + '%'
            })
            this.list = details
        }
    }
}
</script>

<style scoped>
.alias {
    text-align: center;
    line-height: 30px;
}
.list {
    padding: 0 16px 16px 16px;
}
.list .item {
    display: flex;
}
.list .item .col {
    flex: 1;
    line-height: 30px;
}
.col.curr-rate, .col.target-rate, .col.funding-rate {
    text-align: right;
}
</style>