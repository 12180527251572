export default {
    common: {
        confirm: '确定',
        cancel: '取消',
        ok: '好的',
        yes: '是',
        no: '否'
    },
    home: {
        index: {
            initAmount: '初始净值',
            currAmount: '当前净值',
            dayIncome: '当日收益',
            dayIncomeRate: '当日收益率',
            weekAnnualRate: '7日年化收益率',
            monthAnnualRate: '30日年化收益率',
            asset: '币种',
            targetRate: '目标占比',
            currRate: '实际占比',
            currQty: '当前持仓量',
            entryPrice: '开仓价格',
            currPrice: '当前价格',
            notional: '名义价值',
            notionalLevel: '杠杆率',
            dayFundingRate: '当天费率',
            totalIncome: '累计收益',
            oper: '操作',
            panic: 'Panic'
        },
        login: {
            title: '登录',
            username: '账户',
            password: '密码',
            login: '登录'
        }
    }
}