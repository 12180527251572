<template>
<div id="container" class="container">
    <top-bar @changeversion="changeVersion"></top-bar>
    <div class="summary">
        <div class="item">
            <div class="label" v-text="$t('home.index.initAmount')"></div>
            <div class="value" v-text="initAmount"></div>
        </div>
        <div class="item">
            <div class="label" v-text="$t('home.index.currAmount')"></div>
            <div class="value" v-text="currAmount"></div>
        </div>
        <div class="item">
            <div class="label" v-text="'总名义价值'"></div>
            <div class="value" v-text="totalNotional"></div>
        </div>
        <div class="item">
            <div class="label" v-text="$t('home.index.dayIncome')"></div>
            <div class="value" v-text="dayIncome"></div>
        </div>
        <div class="item">
            <div class="label" v-text="$t('home.index.dayIncomeRate')"></div>
            <div class="value" v-text="dayIncomeRate"></div>
        </div>
        <div class="item">
            <div class="label" v-text="$t('home.index.weekAnnualRate')"></div>
            <div class="value" v-text="weekAnnualRate"></div>
        </div>
        <div class="item">
            <div class="label" v-text="$t('home.index.monthAnnualRate')"></div>
            <div class="value" v-text="monthAnnualRate"></div>
        </div>
    </div>
    <div class="details">
        <div class="ls">
            <div class="ls-head">
                <div class="ls-row">
                    <div class="ls-col col-asset" v-text="$t('home.index.asset')"></div>
                    <div class="ls-col col-target-rate" v-text="$t('home.index.targetRate')"></div>
                    <div class="ls-col col-curr-rate" v-text="$t('home.index.currRate')"></div>
                    <div class="ls-col col-notional" v-text="$t('home.index.notional')"></div>
                    <div class="ls-col col-curr-qty" v-text="$t('home.index.currQty')"></div>
                    <div class="ls-col col-entry-price" v-text="$t('home.index.entryPrice')"></div>
                    <div class="ls-col col-curr-price" v-text="$t('home.index.currPrice')"></div>
                    <div class="ls-col col-price-diff" v-text="'期现价差'"></div>
                    <div class="ls-col col-last-day-funding-rate" v-text="$t('home.index.dayFundingRate')"></div>
                    <div class="ls-col col-oper">
                        <div class="oper"><button class="button warning-button" @click="clickReduce('__ALL__', '')" v-text="'Reduce'"></button></div>
                        <div class="oper"><button class="button danger-button" @click="clickPanic('__ALL__')" v-text="$t('home.index.panic')"></button></div>
                    </div>
                </div>
            </div>
            <div class="ls-body">
                <div class="ls-row" v-for="item in itemList" :key="item.Asset">
                    <div class="ls-col col-asset" v-text="item.Asset"></div>
                    <div class="ls-col col-target-rate" v-text="item.TargetRate"></div>
                    <div class="ls-col col-curr-rate" v-text="item.CurrRate"></div>
                    <div class="ls-col col-notional" v-text="item.Notional"></div>
                    <div class="ls-col col-curr-qty" v-text="item.CurrQty"></div>
                    <div class="ls-col col-entry-price" v-text="item.EntryPrice"></div>
                    <div class="ls-col col-curr-price" v-text="item.CurrPrice"></div>
                    <div class="ls-col col-price-diff" v-text="item.PriceDiff"></div>
                    <div class="ls-col col-last-day-funding-rate" v-text="item.LastDayFundingRate"></div>
                    <div class="ls-col col-oper">
                        <div class="oper"><button class="button warning-button" @click="clickReduce(item.Asset, item.PriceDiff)" v-text="'Reduce'"></button></div>
                        <div class="oper"><button class="button danger-button" @click="clickPanic(item.Asset)" v-text="$t('home.index.panic')"></button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tooltip"></div>
    <dlg-reduce :asset="asset" :price-diff="priceDiff" :show="showReduce" @confirm="clickReduceConfirm" @close="clickReduceClose"></dlg-reduce>
    <dlg-panic :asset="asset" :show="showPanic" @confirm="clickPanicConfirm" @close="clickPanicClose"></dlg-panic>
    <msg-box :show="showMsgBox" @confirm="clickMsgBoxConfirm" @close="clickMsgBoxClose"></msg-box>
</div>
</template>

<script>
import abs from '@/assets/js/abs';
import TopBar from "@/components/TopBar";
import DlgReduce from "@/components/DlgReduce";
import DlgPanic from "@/components/DlgPanic";
import MsgBox from "@/components/MsgBox";
export default {
    name: "HomeIndex",
    components: {MsgBox, TopBar, DlgReduce, DlgPanic},
    data() {
        return {
            totalNotional: '0',
            initAmount: '0',
            currAmount: '0',
            dayIncome: '0',
            dayIncomeRate: '0',
            weekAnnualRate: '0',
            monthAnnualRate: '0',
            itemList: [],
            timer: null,

            showReduce: false,
            showPanic: false,
            showMsgBox: false,
            mode: '',
            asset: '',
            priceDiff: '',
            reducePercent: '',
            reduceDuration: '',
            reducePriceRate: '',
            reducePriceOptimal: '',
            reduceInvestable: '',
            panicDuration: '',
            panicInvestable: '',
            code: '',
        };
    },
    mounted () {
        var _this = this
        this.load()
        this.timer = setInterval(function () {
            _this.load()
        }, 5000)
    },
    unmounted () {
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        load: function () {
            this.api.summary(this, null, function (data) {
                this.totalNotional = abs.Util.formatNumber(abs.Util.round(data.Data.TotalNotional, 2), 2)
                this.initAmount = abs.Util.formatNumber(abs.Util.round(Number(data.Data.InitAmount), 2), 2)
                this.currAmount = abs.Util.formatNumber(abs.Util.round(Number(data.Data.CurrAmount), 2), 2)
                this.dayIncome = abs.Util.formatNumber(abs.Util.round(Number(data.Data.DayIncome), 2), 2)
                this.dayIncomeRate = abs.Util.formatNumber(abs.Util.round(Number(data.Data.DayIncomeRate) * 100, 2), 2) + '%'
                this.weekAnnualRate = abs.Util.formatNumber(abs.Util.round(Number(data.Data.WeekAnnualRate) * 100, 2), 2) + '%'
                this.monthAnnualRate = abs.Util.formatNumber(abs.Util.round(Number(data.Data.MonthAnnualRate) * 100, 2), 2) + '%'
            }, function (message) {
                console.log(message)
            });
            this.api.details(this, null, function (data) {
                data.Data.Symbols.sort(function (item1, item2) {
                    return Number(item2.LastDayFundingRate) - Number(item1.LastDayFundingRate)
                })
                let symbols = []
                data.Data.Symbols.forEach(item => {
                    if (!Number(item.Notional)) {
                        return
                    }
                    if (!item.Asset) {
                        item.Asset = item.Symbol
                    }
                    item.TargetRate = abs.Util.formatNumber(abs.Util.round(Number(item.TargetRate) * 100, 2), 2) + '%'
                    item.CurrRate = abs.Util.formatNumber(abs.Util.round(Number(item.CurrRate) * 100, 2), 2) + '%'
                    item.EntryPrice = '$' + abs.Util.formatNumber(Number(item.EntryPrice), 4)
                    item.CurrPrice = '$' + abs.Util.formatNumber(Number(item.CurrPrice), 4)
                    item.PriceDiff = abs.Util.formatNumber(Number(item.PriceDiff) * 100, 2) + '%'
                    item.LastDayFundingRate = abs.Util.formatNumber(abs.Util.round(Number(item.LastDayFundingRate) * 100, 3), 3) + '%'
                    item.Notional = '$' + abs.Util.formatNumber(Number(item.Notional), 2)
                    item.NotionalLevel = abs.Util.formatNumber(Number(item.NotionalLevel), 3)
                    symbols.push(item)
                })
                this.itemList = symbols;
            }, function (message) {
                console.log(message)
            });
        },
        clickReduce: function (asset, priceDiff) {
            this.mode = 'reduce';
            this.asset = asset;
            this.priceDiff = priceDiff;
            this.showReduce = true;
        },
        clickPanic: function (asset) {
            this.mode = 'panic';
            this.asset = asset;
            this.showPanic = true;
        },
        clickReduceConfirm: function (percent, duration, priceRate, investable, code) {
            if (percent === '') {
                this.abs.Toast.show('请指定比例')
                return
            }
            if (duration === '') {
                duration = '0'
            }
            var priceOptimal = (priceRate !== '')
            priceRate = priceRate || '0'

            this.reducePercent = String(percent)
            this.reduceDuration = String(duration)
            this.reducePriceRate = String(priceRate)
            this.reducePriceOptimal = String(priceOptimal)
            this.reduceInvestable = String(investable)
            this.code = code
            this.api.panic(this, {
                asset: this.asset,
                percent: this.reducePercent,
                duration: this.reduceDuration,
                price_rate: this.reducePriceRate,
                price_optimal: this.reducePriceOptimal,
                investable: this.reduceInvestable,
                code: this.code
            }, function () {
                this.showReduce = false;
                this.abs.Toast.show('Reduce任务已创建')
            }, function (message, code) {
                if (code !== 1) {
                    this.abs.Toast.show(message)
                    return
                }
                this.showMsgBox = true
            });
        },
        clickPanicConfirm: function (percent, duration, priceRate, investable, code) {
            if (duration === '') {
                duration = '0'
            }

            this.panicDuration = String(duration)
            this.panicInvestable = String(investable)
            this.code = code
            this.api.panic(this, {
                asset: this.asset,
                percent: '1',
                duration: this.panicDuration,
                price_rate: '0',
                price_optimal: 'false',
                investable: this.panicInvestable,
                code: this.code
            }, function () {
                this.showPanic = false;
                this.abs.Toast.show('Panic任务已创建')
            }, function (message, code) {
                if (code !== 1) {
                    this.abs.Toast.show(message)
                    return
                }
                this.showMsgBox = true
            });
        },
        clickReduceClose: function () {
            this.showReduce = false;
        },
        clickPanicClose: function () {
            this.showPanic = false;
        },
        clickMsgBoxConfirm: function () {
            this.showMsgBox = false;
            this.api.panic(this, {
                asset: this.asset,
                percent: this.mode === 'panic' ? '1' : this.reducePercent,
                duration: this.mode === 'panic' ? this.panicDuration : this.reduceDuration,
                price_rate: this.mode === 'panic' ? '0' : this.reducePriceRate,
                price_optimal: this.mode === 'panic' ? 'false' : this.reducePriceOptimal,
                investable: this.mode === 'panic' ? this.panicInvestable : this.reduceInvestable,
                code: this.code,
                confirm: String(true)
            }, function () {
                this.showReduce = false;
                this.showPanic = false;
                this.abs.Toast.show(this.mode === 'panic' ? 'Panic任务已创建' : 'Reduce任务已创建')
            }, function (message) {
                this.abs.Toast.show(message)
            });
        },
        clickMsgBoxClose: function () {
            this.showMsgBox = false;
        },
        changeVersion: function (version) {
            console.log(version)
            this.load()
        }
    }
}
</script>

<style scoped>
.summary {
    display: flex;
    padding: 20px 80px;
}
.summary .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.summary .item .label {
    padding-right: 4px;
    color: var(--text-secondary);
}
.summary .item .value {
    line-height: 30px;
    font-size: 16px;
    font-weight: 500;
}
.details {
    padding: 0 80px 40px 80px;
}
.ls {
    border: 1px solid var(--sep-color);
    border-radius: 6px;
}
.ls .ls-head .ls-col {
    font-weight: 700;
}
.ls .ls-head .ls-col.col-oper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ls .ls-body .ls-row {
    border-top: 1px solid var(--sep-color);
}
.ls .ls-row {
    display: flex;
    padding: 0 20px;
}
.ls .ls-col {
    flex: 1;
    line-height: 50px;
    text-align: right;
}
.ls .ls-col.col-asset {
    text-align: center;
}
.ls .ls-col.col-oper {
    flex: none;
    width: 168px;
    padding-left: 20px;
    text-align: center;
}
.ls .ls-body .ls-col.col-oper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ls .ls-col.col-oper .oper {
    padding: 0 4px;
}
.ls .ls-col.col-oper .oper .button {
    padding: 0 10px;
}
</style>