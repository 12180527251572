<template>
<div class="container">
    <div class="top-bar">
        <div class="title">资金费率策略运行数据</div>
    </div>
    <div class="content">
        <div class="box">
            <div class="item">
                <div class="label">账户:</div>
                <div class="field"><input class="text" type="text" v-model="name" @keypress="keypressName" /></div>
            </div>
            <div class="item">
                <div class="label">密码:</div>
                <div class="field"><input class="text" type="password" v-model="pswd" @keypress="keypressPswd" /></div>
            </div>
            <div class="item">
                <div class="label"></div>
                <div class="field"><button class="button primary-button" @click="clickLogin">登录</button></div>
            </div>
        </div>
    </div>
    <msg-toast></msg-toast>
</div>
</template>

<script>
import MsgToast from "@/components/MsgToast";
export default {
    name: "HomeLogin",
    components: {MsgToast},
    data () {
        return {
            name: '',
            pswd: ''
        }
    },
    mounted () {
        this.name = this.abs.Store.get('name')
    },
    methods: {
        keypressName: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickLogin()
        },
        keypressPswd: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickLogin()
        },
        clickLogin: function () {
            this.api.login(this, {
                name: this.name,
                pswd: this.pswd
            }, function (data) {
                this.abs.Store.set('name', data.Data.Name)
                this.abs.Store.uticket(data.Data.Uticket)
                this.$router.push(this.abs.routes.HOME_INDEX)
            }, function (message) {
                this.abs.Toast.show(message)
            });
        }
    }
}
</script>

<style scoped>
.top-bar {
    height: 60px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.top-bar .title {
    padding: 0 80px;
    font-size: 18px;
}
.content {
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box {
    border: 1px solid var(--sep-color);
    border-radius: 6px;
    padding: 40px 60px;
}
.item {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item .label {
    width: 50px;
    color: var(--text-secondary);
}
.item .field {
    flex: 1;
}
.item .button {
    width: 100%;
}
.demo{}
</style>