<template>
<div class="dlg-panic" :class="{'show':show}">
    <div class="content">
        <div class="title"><div class="text">Panic确认</div><div class="close" @click="clickClose"><i class="icon times"></i></div></div>
        <div class="box">
            <div class="item">
                <div class="label">币种:</div>
                <div class="field"><div class="static" :class="{'warning':asset === '__ALL__'}" v-text="asset === '__ALL__' ? '所有币种' : asset"></div></div>
            </div>
<!--            <div class="item" style="margin-bottom:4px">-->
<!--                <div class="label">比例:</div>-->
<!--                <div class="field"><input class="text" type="number" v-model="percent" placeholder="输入数字0 ~ 1" /></div>-->
<!--            </div>-->
<!--            <div class="tips">针对当前仓位的减仓比例，清仓则输入1</div>-->
            <div class="item" style="margin-bottom:4px">
                <div class="label">周期:</div>
                <div class="field"><input class="text" type="number" v-model="duration" placeholder="输入数字，单位:分钟" /></div>
            </div>
            <div class="tips">如果设置了执行周期，整个Panic会在指定的时间周期内执行完<br/><span style="color:red;font-size:13px">不填则表示单笔全额执行(谨慎)</span></div>
<!--            <div class="item" style="margin-bottom:4px">-->
<!--                <div class="label">价差:</div>-->
<!--                <div class="field"><input class="text" type="number" v-model="priceRate" placeholder="(合约价-现货价)/现货价" /></div>-->
<!--            </div>-->
<!--            <div class="tips" style="max-width:400px">如果设置了价差，只有当价差小于设定值时才会执行<br/>当执行周期结束时，总执行比例可能小于设定比例</div>-->
            <div class="item">
                <div class="label">重投资:</div>
                <div class="field"><label style="display:flex;align-items:center;color:var(--text-secondary);cursor:pointer;"><input class="checkbox" style="width:16px;height:16px;margin-right:4px" type="checkbox" v-model="investable" />是否可用于重投资</label></div>
            </div>
            <div class="item">
                <div class="label">口令:</div>
                <div class="field"><input class="text" type="password" v-model="code" @keypress="keypressCode" /></div>
            </div>
            <div class="item">
                <div class="label"></div>
                <div class="field"><button class="button primary-button" @click="clickConfirm">确定</button></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DlgPanic",
    props: {
        asset: {
            type: String,
            default: () => {
                return '';
            }
        },
        show: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    data () {
        return {
            percent: '1',
            duration: '',
            priceRate: '',
            investable: false,
            code: ''
        };
    },
    methods: {
        clickClose: function () {
            this.$emit('close')
        },
        keypressCode: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickConfirm()
        },
        clickConfirm: function () {
            if (!this.code) {
                this.abs.Toast.show('请输入口令')
                return
            }
            this.$emit('confirm', this.percent, this.duration, this.priceRate, this.investable, this.code)
        }
    }
}
</script>

<style scoped>
.dlg-panic {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.dlg-panic.show {
    display: flex;
}
.dlg-panic .content {
    border-radius: 6px;
    background-color: var(--background-default);
}
.dlg-panic .title {
    padding: 0 20px;
    line-height: 48px;
    border-bottom: 1px solid var(--sep-color);
    text-align: center;
}
.dlg-panic .title .close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.dlg-panic .box {
    border-radius: 6px;
    padding: 40px 80px;
}
.dlg-panic .item {
    width: 300px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dlg-panic .item .label {
    width: 56px;
    padding-right: 10px;
    text-align: right;
    color: var(--text-secondary);
}
.dlg-panic .item .field {
    flex: 1;
}
.dlg-panic .item .button {
    width: 100%;
}
.dlg-panic .tips {
    padding-left: 56px;
    color: var(--text-tertiary);
    font-size: 13px;
    margin-bottom: 16px;
}
.dlg-panic .warning {
    font-weight: bold;
    color: var(--danger);
}
</style>