import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";

import en_us from "@/assets/res/en_us";
import zh_cn from "@/assets/res/zh_cn";

import "@/assets/css/abs.css";
import abs from "@/assets/js/abs";
import api from "@/assets/js/api";
import MsgToast from "@/components/MsgToast";

import App from './App.vue'
import HomeIndex from "@/HomeIndex";
import HomeLogin from "@/HomeLogin";
import SymbolDetail from "@/SymbolDetail";

const router = createRouter({
    history: createWebHistory(),
    routes: [{
        path: abs.routes.HOME_INDEX,
        component: HomeIndex
    }, {
        path: abs.routes.HOME_LOGIN,
        component: HomeLogin
    }, {
        path: abs.routes.SYMBOL_DETAIL,
        component: SymbolDetail
    }]
});

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'zh-CN',
    datetimeFormats: {
        'en-US': {
            datetime: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            date: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            }
        },
        'zh-CN': {
            datetime: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            date: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            }
        }
    },
    messages: {
        'en-US': en_us,
        'zh-CN': zh_cn
    }
});

const app = createApp(App)
app.use(router)
app.use(i18n)
app.config.globalProperties.abs = abs;
app.config.globalProperties.api = api;
app.component('MsgToast', MsgToast);
app.mount('#app')
