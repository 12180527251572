export default {
    common: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        ok: 'Ok',
        yes: 'Yes',
        no: 'No',
    },
    home: {
        index: {
            slogan_1: 'Welcome to',
        },
        login: {
            title: 'Sign in',
            phone: 'Phone',
            email: 'Email',
            phone_number: 'Phone number',
            email_address: 'Email address',
            password: 'Password',
            sign_up: 'Sign up',
            sign_in: 'Sign in',
            no_account: 'Don\'t have an account? ',
        }
    }
}