<template>
<div class="top-bar">
    <div class="title">资金费率策略运行数据</div>
    <div class="versions">
        <div class="version" :class="{'active':activeVersion==='BASIC1'}" @click="clickVersion('BASIC1')">
            <div class="text">BASIC1</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='BASIC2'}" @click="clickVersion('BASIC2')">
            <div class="text">BASIC2</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='BASIC3'}" @click="clickVersion('BASIC3')">
            <div class="text">BASIC3</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI2'}" @click="clickVersion('UNI2')">
            <div class="text">UNI2</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI3'}" @click="clickVersion('UNI3')">
            <div class="text">UNI3</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI4'}" @click="clickVersion('UNI4')">
            <div class="text">UNI4</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI5'}" @click="clickVersion('UNI5')">
            <div class="text">UNI5</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI6'}" @click="clickVersion('UNI6')">
            <div class="text">UNI6</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='UNI8'}" @click="clickVersion('UNI8')">
            <div class="text">UNI8</div>
        </div>
        <div class="version" :class="{'active':activeVersion==='OKX2'}" @click="clickVersion('OKX2')">
            <div class="text">OKX2</div>
        </div>
    </div>
    <div class="account" :class="{'show':account}">
        <div class="item" v-text="name" @click="clickShowPswd"></div>
        <div class="item" v-text="'退出'" @click="clickLogout"></div>
    </div>
    <div class="dlg-pswd" :class="{'show':showPswd}">
        <div class="content">
            <div class="title"><div class="text">修改密码</div><div class="close" @click="clickClosePswd"><i class="icon times"></i></div></div>
            <div class="box">
                <div class="item">
                    <div class="label">原密码:</div>
                    <div class="field"><input class="text" type="password" v-model="opswd" @keypress="keypressOpswd" /></div>
                </div>
                <div class="item">
                    <div class="label">新密码:</div>
                    <div class="field"><input class="text" type="password" v-model="npswd" @keypress="keypressNpswd" /></div>
                </div>
                <div class="item">
                    <div class="label"></div>
                    <div class="field"><input class="text" type="password" v-model="npswdAgain" @keypress="keypressNpswdAgain" /></div>
                </div>
                <div class="item">
                    <div class="label"></div>
                    <div class="field"><button class="button primary-button" @click="clickPswd">修改</button></div>
                </div>
            </div>
        </div>
    </div>
    <msg-toast></msg-toast>
</div>
</template>

<script>
import MsgToast from "@/components/MsgToast";
export default {
    name: "TopBar",
    components: {MsgToast},
    props: {
        account: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
    },
    data () {
        return {
            name: '',
            showPswd: false,
            opswd: '',
            npswd: '',
            npswdAgain: '',
            activeVersion: ''
        };
    },
    mounted () {
        this.name = this.abs.Store.get('name')
        this.clickVersion('BASIC1')
    },
    methods: {
        clickShowPswd: function () {
            this.opswd = ''
            this.npswd = ''
            this.npswdAgain = ''
            this.showPswd = true
        },
        clickClosePswd: function () {
            this.showPswd = false
        },
        clickLogout: function () {
            this.abs.Store.uticket('')
            this.$router.replace(this.abs.routes.HOME_LOGIN)
        },
        keypressOpswd: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickPswd()
        },
        keypressNpswd: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickPswd()
        },
        keypressNpswdAgain: function (e) {
            if (e.code !== 'Enter') {
                return
            }
            this.clickPswd()
        },
        clickPswd: function () {
            if (!this.opswd) {
                this.abs.Toast.show('请输入原密码')
                return
            }
            if (!this.npswd) {
                this.abs.Toast.show('请输入新密码')
                return
            }
            if (this.npswd !== this.npswdAgain) {
                this.abs.Toast.show('两次新密码不一致')
                return
            }
            this.api.pswd(this, {
                opswd: this.opswd,
                npswd: this.npswd
            }, function () {
                this.showPswd = false
                this.abs.Toast.show('密码修改成功')
            }, function (message) {
                this.abs.Toast.show(message)
            });
        },
        clickVersion: function (version) {
            this.activeVersion = version
            this.api.url(this.api.urlFromVersion(version))
            this.$emit('changeversion', version)
        }
    }
}
</script>

<style scoped>
.top-bar {
    height: 60px;
    border-bottom: 1px solid var(--sep-color);
    display: flex;
    align-items: center;
}
.title {
    padding: 0 40px;
    font-size: 18px;
}
.title .close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.versions {
    flex: 1;
    display: flex;
    justify-content: center;
}
.versions .version {
    width: 80px;
    height: 36px;
    margin: 0 4px;
    background-color: #eeeeee;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.versions .version.active {
    background-color: #409eff;
}
.versions .version.active .text {
    color: #ffffff;
}
.account {
    padding: 0 40px;
    display: none;
    justify-content: flex-end;
}
.account.show {
    display: flex;
}
.account .item {
    line-height: 36px;
    margin-left: 20px;
    cursor: pointer;
}
.dlg-pswd {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.dlg-pswd.show {
    display: flex;
}
.dlg-pswd .content {
    border-radius: 6px;
    background-color: var(--background-default);
}
.dlg-pswd .title {
    padding: 0 20px;
    line-height: 48px;
    border-bottom: 1px solid var(--sep-color);
    text-align: center;
}
.dlg-pswd .box {
    border-radius: 6px;
    padding: 40px 80px;
}
.dlg-pswd .item {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dlg-pswd .item .label {
    width: 60px;
    color: var(--text-secondary);
}
.dlg-pswd .item .field {
    flex: 1;
}
.dlg-pswd .item .button {
    width: 100%;
}
.demo{}
</style>