<template>
<div class="msg-box" :class="{'show':show}">
    <div class="content">
        <div class="title"><div class="text">Panic确认</div><div class="close" @click="clickClose"><i class="icon times"></i></div></div>
        <div class="box">
            <div class="msg" style="font-size:16px;color:var(--danger)">未设置执行周期，确定要一笔执行吗？</div>
        </div>
        <div class="foot">
            <div class="item"><button class="button primary-button" @click="clickConfirm">确定</button></div>
            <div class="item"><button class="button link-button" @click="clickClose">取消</button></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "MsgBox",
    props: {
        show: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    methods: {
        clickClose: function () {
            this.$emit('close')
        },
        clickConfirm: function () {
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped>
.msg-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.msg-box.show {
    display: flex;
}
.msg-box .content {
    border-radius: 6px;
    background-color: var(--background-default);
}
.msg-box .title {
    padding: 0 20px;
    line-height: 48px;
    border-bottom: 1px solid var(--sep-color);
    text-align: center;
}
.msg-box .title .close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.msg-box .box {
    border-radius: 6px;
    padding: 40px 80px;
}
.msg-box .foot {
    height: 40px;
    border-top: 1px solid var(--sep-color);
    display: flex;
}
.msg-box .foot .item {
    flex: 1;
}
.msg-box .foot .item .button {
    width: 100%;
    height: 100%;
}
</style>