import abs from '@/assets/js/abs';

let apiUrl = ''
let apiUrlBasic1 = 'http://54.249.250.235:9601'
let apiUrlBasic2 = 'http://35.77.161.166:9602'
let apiUrlBasic3 = 'http://52.68.2.114:9603'
let apiUrlUni2 = 'http://52.69.133.114:9902'
let apiUrlUni3 = 'http://54.64.50.92:9903'
let apiUrlUni4 = 'http://35.79.230.42:9904'
let apiUrlUni5 = 'http://54.65.178.145:9905'
let apiUrlUni6 = 'http://18.178.239.234:9906'
let apiUrlUni8 = 'http://54.150.2.135:9908'
let apiUrlOkx2 = 'http://18.177.176.227:9802'
let invoke = function (uri, _this, body, success, failure) {
    body = body || {}
    body.uticket = abs.Store.uticket() || ''
    fetch(apiUrl + uri, {
        method: 'POST',
        body: JSON.stringify(body)
    }).then(response => response.json()).then(data => {
        if (data.Code !== 0) {
            if (data.Code === 9) {
                _this.$router.push(_this.abs.routes.HOME_LOGIN);
            }
            else {
                failure && failure.call(_this, data.Message, data.Code);
            }
        }
        else {
            success && success.call(_this, data);
        }
    }).catch(error => {
        failure && failure.call(_this, error);
    })
};

export default {
    API_URL_BASIC1: apiUrlBasic1,
    API_URL_BASIC2: apiUrlBasic2,
    API_URL_BASIC3: apiUrlBasic3,
    API_URL_UNI2: apiUrlUni2,
    API_URL_UNI3: apiUrlUni3,
    API_URL_UNI4: apiUrlUni4,
    API_URL_UNI5: apiUrlUni5,
    API_URL_UNI6: apiUrlUni6,
    API_URL_UNI8: apiUrlUni8,
    API_URL_OKX2: apiUrlOkx2,
    url: function (url) {
        apiUrl = url
    },
    urlFromAlias: function (alias) {
        let url = ''
        if (alias === 'BASIC1') {
            url = apiUrlBasic1
        } else if (alias === 'BASIC2') {
            url = apiUrlBasic2
        } else if (alias === 'BASIC3') {
            url = apiUrlBasic3
        } else if (alias === 'UNI2') {
            url = apiUrlUni2
        } else if (alias === 'UNI3') {
            url = apiUrlUni3
        } else if (alias === 'UNI4') {
            url = apiUrlUni4
        } else if (alias === 'UNI5') {
            url = apiUrlUni5
        } else if (alias === 'UNI6') {
            url = apiUrlUni6
        } else if (alias === 'UNI8') {
            url = apiUrlUni8
        } else if (alias === 'OKX2') {
            url = apiUrlOkx2
        }
        return url
    },
    urlFromVersion: function (version) {
        let url = ''
        if (version === 'BASIC1') {
            url = apiUrlBasic1
        } else if (version === 'BASIC2') {
            url = apiUrlBasic2
        } else if (version === 'BASIC3') {
            url = apiUrlBasic3
        } else if (version === 'UNI2') {
            url = apiUrlUni2
        } else if (version === 'UNI3') {
            url = apiUrlUni3
        } else if (version === 'UNI4') {
            url = apiUrlUni4
        } else if (version === 'UNI5') {
            url = apiUrlUni5
        } else if (version === 'UNI6') {
            url = apiUrlUni6
        } else if (version === 'UNI8') {
            url = apiUrlUni8
        } else if (version === 'OKX2') {
            url = apiUrlOkx2
        }
        return url
    },
    login: function (_this, data, success, failure) {
        invoke('/login', _this, data, success, failure);
    },
    pswd: function (_this, data, success, failure) {
        invoke('/pswd', _this, data, success, failure);
    },
    vcode: function (_this, data, success, failure) {
        invoke('/vcode', _this, data, success, failure);
    },
    summary: function (_this, data, success, failure) {
        invoke('/summary', _this, data, success, failure);
    },
    details: function (_this, data, success, failure) {
        invoke('/details', _this, data, success, failure);
    },
    timeline: function (_this, data, success, failure) {
        invoke('/timeline', _this, data, success, failure);
    },
    panic: function (_this, data, success, failure) {
        invoke('/panic', _this, data, success, failure);
    }
}